import { Builder } from "@builder.io/react";
import {
  type LabelProps,
  Label as SystemLabel,
} from "@mioto/design-system/Label";

const Label = ({ children, size }: Pick<LabelProps, "children" | "size">) => {
  return <SystemLabel size={size}>{children}</SystemLabel>;
};

Builder.registerComponent(Label, {
  name: "Form:Label",
  override: true,
  inputs: [
    {
      name: "children",
      type: "text",
      defaultValue: "Badgetext",
      friendlyName: "Content of the Badge",
    },
    {
      name: "size",
      type: "string",
      enum: ["small", "medium", "large"],
      defaultValue: "medium",
      friendlyName: "Size of the Badge",
    },
  ],
});
