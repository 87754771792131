"use client";

import { Builder } from "@builder.io/react";

import "./Button";
import "./Heading";
import "./Text";
import "./Separator";
import "./Badge";
import "./Link";
import "./Logo";
import "./Input";
import "./SubmitButton";
import "./Label";
import "./Header";
import "./CustomText";
import "./ButtonLink";
import "./ContactForm";
import "./TeamImage";
import "./Accordion";

Builder.register("insertMenu", {
  name: "Typography",
  items: [
    { item: "Heading", name: "Heading" },
    { item: "Text", name: "Text" },
    { item: "Custom Text", name: "Custom Text" },
    { item: "Link", name: "Link" },
    { item: "ButtonLink", name: "ButtonLink" },
  ],
});

Builder.register("insertMenu", {
  name: "Brand",
  items: [{ item: "Logo", name: "Logo" }],
});

Builder.register("insertMenu", {
  name: "Organisms",
  items: [
    { item: "Header", name: "Header" },
    { item: "ContactForm", name: "ContactForm" },
    { item: "TeamImage", name: "TeamImage" },
    { item: "Accordion", name: "Accordion" },
  ],
});
