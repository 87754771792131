import { createEnv as libCreateEnv } from "@t3-oss/env-core";
import type { ZodType } from "zod";

export function createEnv<TVars extends Record<string, ZodType>>(vars: TVars) {
  return libCreateEnv<undefined, TVars>({
    server: vars as any,
    emptyStringAsUndefined: true,
    skipValidation: Boolean(process.env.SKIP_ENV_VALIDATION),
    runtimeEnvStrict: Object.fromEntries(
      Object.entries(vars).map(([key, value]) => [key, process.env[key]]),
    ) as any,
  });
}

export function createClientEnv<
  TClientVars extends Record<`NEXT_PUBLIC_${string}`, ZodType>,
>(
  vars: TClientVars,
  runtimeEnv: Record<keyof TClientVars, string | boolean | number | undefined>,
) {
  return libCreateEnv({
    emptyStringAsUndefined: true,
    skipValidation: Boolean(process.env.SKIP_ENV_VALIDATION),
    client: vars as any,
    clientPrefix: "NEXT_PUBLIC_",
    runtimeEnvStrict: runtimeEnv,
  });
}
