import { Builder } from "@builder.io/react";
import {
  type SeparatorProps,
  Separator as SystemSeparator,
} from "@mioto/design-system/Separator";

const Separator = ({ orientation }: Pick<SeparatorProps, "orientation">) => {
  return <SystemSeparator orientation={orientation} />;
};

Builder.registerComponent(Separator, {
  name: "Separator",
  inputs: [
    {
      name: "orientation",
      type: "string",
      defaultValue: "horizontal",
      enum: ["horizontal", "vertical"],
    },
  ],
});
