import type { Props } from "./Logo";

export const LogoLarge = ({
  className,
  monochrome = false,
}: Omit<Props, "large">) => {
  return (
    <svg
      viewBox="0 0 275 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ aspectRatio: "16/9" }}
      width="100%"
      height="100%"
    >
      <title>Mioto Logo</title>
      <path
        d="M35.6424 34.092L20.2586 7.44989C19.3484 5.87689 17.073 5.87689 16.1628 7.44989L0.779082 34.092C-0.259694 35.8926 -0.259694 38.1185 0.779082 39.919L16.1628 66.5612C17.073 68.1342 19.3484 68.1342 20.2586 66.5612L35.6424 39.919C36.6811 38.1185 36.6811 35.8926 35.6424 34.092Z"
        className={monochrome ? "fill-black" : "fill-primary7"}
      />
      <path
        d="M78.2092 40.9076H47.4416C45.3641 40.9076 43.4349 42.0156 42.3961 43.8162L27.0124 70.4583C26.1022 72.0313 27.2399 74 29.0603 74H59.8278C61.9053 74 63.8345 72.892 64.8733 71.0914L80.257 44.4493C81.1672 42.8763 80.0295 40.9076 78.2092 40.9076Z"
        className={monochrome ? "fill-black" : "fill-tertiary7"}
      />
      <path
        d="M27.0145 3.54173L42.3983 30.1838C43.4371 31.9844 45.3662 33.0924 47.4438 33.0924H78.2113C80.0317 33.0924 81.1694 31.1237 80.2592 29.5507C69.6934 11.2682 50.1743 0 29.0525 0C27.2322 0 26.0945 1.96873 27.0047 3.54173H27.0145Z"
        className={monochrome ? "fill-black" : "fill-secondary7"}
      />
      <path
        d="M104 58V19.36H112.16L123.5 42.58L134.84 19.36H142.94V58H134.84V32.86L125.84 51.34H121.16L112.1 32.86V58H104Z"
        className="fill-black"
      />
      <path d="M149.761 58V19.36H157.861V58H149.761Z" className="fill-black" />
      <path
        d="M182.283 58.6C169.763 58.6 163.503 52.02 163.503 38.86C163.503 31.78 165.083 26.66 168.243 23.5C171.403 20.34 176.083 18.76 182.283 18.76C188.483 18.76 193.163 20.34 196.323 23.5C199.483 26.66 201.063 31.78 201.063 38.86C201.063 52.02 194.803 58.6 182.283 58.6ZM182.283 51.7C185.923 51.7 188.583 50.66 190.263 48.58C191.983 46.46 192.843 43.22 192.843 38.86C192.843 34.02 191.983 30.62 190.263 28.66C188.583 26.66 185.923 25.66 182.283 25.66C178.643 25.66 175.963 26.66 174.243 28.66C172.563 30.62 171.723 34.02 171.723 38.86C171.723 43.22 172.563 46.46 174.243 48.58C175.963 50.66 178.643 51.7 182.283 51.7Z"
        className="fill-black"
      />
      <path
        d="M215.022 58V25.6H203.143V19.36H234.943V25.6H223.122V58H215.022Z"
        className="fill-black"
      />
      <path
        d="M255.818 58.6C243.298 58.6 237.038 52.02 237.038 38.86C237.038 31.78 238.618 26.66 241.778 23.5C244.938 20.34 249.618 18.76 255.818 18.76C262.018 18.76 266.698 20.34 269.858 23.5C273.018 26.66 274.598 31.78 274.598 38.86C274.598 52.02 268.338 58.6 255.818 58.6ZM255.818 51.7C259.458 51.7 262.118 50.66 263.798 48.58C265.518 46.46 266.378 43.22 266.378 38.86C266.378 34.02 265.518 30.62 263.798 28.66C262.118 26.66 259.458 25.66 255.818 25.66C252.178 25.66 249.498 26.66 247.778 28.66C246.098 30.62 245.258 34.02 245.258 38.86C245.258 43.22 246.098 46.46 247.778 48.58C249.498 50.66 252.178 51.7 255.818 51.7Z"
        className="fill-black"
      />
    </svg>
  );
};
