import { Builder } from "@builder.io/react";
import {
  type ButtonProps,
  Button as SystemButton,
} from "@mioto/design-system/Button";
import { colorScheme } from "@mioto/design-system/utils/sharedVariants";

const Button = ({
  children,
  size,
  variant,
  round,
  alignByContent,
  colorScheme,
  square,
  emphasize,
  attributes: { key, ...attributes },
}: ButtonInputProps & { attributes: any }) => {
  return (
    <SystemButton
      size={size}
      variant={variant}
      round={round}
      square={square}
      alignByContent={alignByContent}
      colorScheme={colorScheme}
      key={key}
      emphasize={emphasize}
      {...attributes}
    >
      {children}
    </SystemButton>
  );
};

export type ButtonInputProps = Pick<
  ButtonProps,
  | "children"
  | "size"
  | "variant"
  | "round"
  | "square"
  | "alignByContent"
  | "colorScheme"
  | "emphasize"
>;

export const buttonInputs = [
  {
    name: "children",
    type: "text",
    defaultValue: "Buttontext",
    friendlyName: "Content of the Button",
  },
  {
    name: "size",
    type: "string",
    enum: ["small", "medium", "large"],
    defaultValue: "medium",
    friendlyName: "Size of the Button",
  },
  {
    name: "variant",
    type: "string",
    enum: ["primary", "secondary", "tertiary"],
    defaultValue: "primary",
    friendlyName: "Variant of the Button",
  },
  {
    name: "round",
    type: "boolean",
    defaultValue: "false",
  },
  {
    name: "square",
    type: "boolean",
    defaultValue: "false",
  },
  {
    name: "alignByContent",
    type: "boolean",
    defaultValue: "false",
  },
  {
    name: "colorScheme",
    type: "string",
    enum: Object.keys(colorScheme),
    defaultValue: "primary",
  },
  {
    name: "emphasize",
    type: "boolean",
    defaultValue: "false",
  },
];

Builder.registerComponent(Button, {
  name: "Core:Button",
  override: true,
  noWrap: true,
  inputs: buttonInputs,
});
