import { Builder } from "@builder.io/react";

const CustomText = ({ children }: { children: string }) => {
  // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
  return <div dangerouslySetInnerHTML={{ __html: children }} />;
};

Builder.registerComponent(CustomText, {
  name: "Custom Text",
  inputs: [
    {
      name: "children",
      type: "richText",
      defaultValue: "Text",
      friendlyName: "Content of the Text",
    },
  ],
});
