import { Builder } from "@builder.io/react";
import {
  type SubmitButtonProps,
  SubmitButton as SystemSubmitButton,
} from "@mioto/design-system/SubmitButton";
import { colorScheme } from "@mioto/design-system/utils/sharedVariants";

const SubmitButton = ({
  children,
  size,
  variant,
  round,
  alignByContent,
  colorScheme,
  square,
  isLoading,
}: Pick<
  SubmitButtonProps,
  | "children"
  | "size"
  | "variant"
  | "round"
  | "square"
  | "alignByContent"
  | "colorScheme"
  | "isLoading"
>) => {
  return (
    <SystemSubmitButton
      size={size}
      variant={variant}
      round={round}
      square={square}
      alignByContent={alignByContent}
      colorScheme={colorScheme}
      isLoading={isLoading}
    >
      {children}
    </SystemSubmitButton>
  );
};

Builder.registerComponent(SubmitButton, {
  name: "Form:SubmitButton",
  ...Builder.components.find(
    (component) => component.name === "Form:SubmitButton",
  ),
  override: true,
  inputs: [
    {
      name: "children",
      type: "text",
      defaultValue: "Buttontext",
      friendlyName: "Content of the Button",
    },
    {
      name: "size",
      type: "string",
      enum: ["small", "medium", "large"],
      defaultValue: "medium",
      friendlyName: "Size of the Button",
    },
    {
      name: "variant",
      type: "string",
      enum: ["primary", "secondary", "tertiary"],
      defaultValue: "primary",
      friendlyName: "Variant of the Button",
    },
    {
      name: "round",
      type: "boolean",
      defaultValue: "false",
    },
    {
      name: "square",
      type: "boolean",
      defaultValue: "false",
    },
    {
      name: "alignByContent",
      type: "boolean",
      defaultValue: "false",
    },
    {
      name: "colorScheme",
      type: "string",
      enum: Object.keys(colorScheme),
      defaultValue: "primary",
    },
    {
      name: "isLoading",
      type: "boolean",
      defaultValue: "false",
    },
  ],
});
