"use client";

import { BuilderComponent, useIsPreviewing } from "@builder.io/react";
import { builder } from "@builder.io/sdk";
import { Stack } from "@mioto/design-system/Stack";
import "./builderIO";
import { builderClientEnv } from "../../../../env";

builder.init(builderClientEnv.NEXT_PUBLIC_BUILDER_IO, false);

// Define an interface for the BuilderPageProps object
// with a `content` property type `any`
interface BuilderPageProps {
  content: any;
  locale: string;
  NotFound: React.ReactNode;
}

export function BuilderContent({
  content,
  locale,
  NotFound,
}: BuilderPageProps) {
  // Call the useIsPreviewing hook to determine if
  // the page is being previewed in Builder
  const isPreviewing = useIsPreviewing();

  // If `content` has a value or the page is being previewed in Builder,
  // render the BuilderComponent with the specified content and model props.
  if (content || isPreviewing) {
    return <BuilderComponent locale={locale} content={content} model="page" />;
  }

  // If the `content` is falsy and the page is
  // not being previewed in Builder, render the
  // DefaultErrorPage with a 404.
  return <Stack className="h-[100dvh] app-theme">{NotFound}</Stack>;
}

export { Builder } from "@builder.io/react";
