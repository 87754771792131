import { Builder } from "@builder.io/react";
import {
  type BadgeProps,
  Badge as SystemBadge,
} from "@mioto/design-system/Badge";
import { colorScheme } from "@mioto/design-system/utils/sharedVariants";

const Badge = ({
  children,
  colorScheme,
}: Pick<BadgeProps, "children" | "colorScheme">) => {
  return <SystemBadge colorScheme={colorScheme}>{children}</SystemBadge>;
};

Builder.registerComponent(Badge, {
  name: "Badge",
  inputs: [
    {
      name: "children",
      type: "text",
      defaultValue: "Badgetext",
      friendlyName: "Content of the Badge",
    },
    {
      name: "colorScheme",
      type: "string",
      enum: Object.keys(colorScheme),
      defaultValue: "primary",
    },
  ],
});
