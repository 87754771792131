"use client";

import { Builder } from "@builder.io/react";
import type { ButtonVariants } from "@mioto/design-system/Button/classes";
import type { LinkVariants } from "@mioto/design-system/Link/classes";
import { Logo } from "@mioto/design-system/Logo";
import { Overlay } from "@mioto/design-system/Overlay";
import { Row, rowClasses } from "@mioto/design-system/Row";
import { Stack } from "@mioto/design-system/Stack";
import { List } from "@phosphor-icons/react/dist/ssr";
import Link from "next/link";
import * as React from "react";
import { useMedia } from "react-use";
import { buttonInputs } from "./Button";
import "./header.css";
import { ButtonLink } from "@mioto/design-system/Button";
import { pick } from "remeda";
import type { Required } from "utility-types";
import { linkInputs } from "./Link";

type link = {
  label?: string;
  href?: string;
  button: ButtonVariants;
  link: LinkVariants;
  isButton: boolean;
};
type Props = {
  centerLinks: link[];
  innerPadding?: {
    sm?: string;
    md?: string;
    lg?: string;
  };
  rightLinks: link[];
};

function createLinks(links: link[]) {
  return links
    .filter(
      (link): link is Required<link, "href" | "label"> =>
        !!(link.label && link.href),
    )
    .map((link) =>
      link.isButton ? (
        <ButtonLink key={link.label} href={link.href}>
          {link.label}
        </ButtonLink>
      ) : (
        <Link href={link.href} key={link.label}>
          {link.label}
        </Link>
      ),
    );
}

function WebsiteHeader({ centerLinks = [], rightLinks = [] }: Props) {
  const [open, setOpen] = React.useState(false);
  const CenterNavLinks = createLinks(centerLinks);
  const RightNavLinks = createLinks(rightLinks);

  const isDesktop = useMedia("(min-width: 992px)", true);

  return (
    <>
      <Overlay.Root
        className="grid"
        style={{ gridTemplateRows: "max-content 1fr" }}
        open={!isDesktop && open}
        onOpenChange={setOpen}
      >
        <header
          className={rowClasses(
            {},
            `justify-between py-4 flex-0 px-3 lg:px-8 text-mediumText`,
          )}
        >
          <Link href="/home">
            <Logo className="w-[100px]" large />
          </Link>
          <Row className="gap-4 hidden lg:flex items-center">
            {CenterNavLinks}
          </Row>
          <Row className="gap-2 hidden lg:flex items-center">
            {RightNavLinks}
          </Row>
          <Overlay.Trigger className="block lg:hidden">
            <List />
          </Overlay.Trigger>
        </header>
        <Overlay.Content className=" w-full z-10 pt-6 pb-8 px-3 lg:px-8 CollapsibleContent">
          <Stack className="gap-2 py-2 items-start">
            {CenterNavLinks}
            <Stack className="gap-3 mt-2">{RightNavLinks}</Stack>
          </Stack>
        </Overlay.Content>
      </Overlay.Root>
    </>
  );
}

const inputs = [
  {
    name: "label",
    type: "string",
    required: true,
    friendlyName: "Label",
  },
  {
    name: "href",
    type: "string",
    required: true,
    friendlyName: "Ziel",
  },
  {
    name: "link",
    type: "object",
    subFields: Object.values(
      pick(linkInputs, ["emphasize", "size", "underline"]),
    ),
  },
  {
    name: "isButton",
    type: "boolean",
    defaultValue: false,
    friendlyName: "Als Button anzeigen",
  },
  {
    name: "button",
    type: "object",
    subFields: buttonInputs.filter((input) => input.name !== "children"),
  },
];

Builder.registerComponent(WebsiteHeader, {
  name: "Header",
  inputs: [
    {
      name: "centerLinks",
      type: "list",
      defaultValue: [],
      subFields: inputs,
    },
    {
      name: "rightLinks",
      type: "list",
      defaultValue: [],
      subFields: inputs,
    },
  ],
});
