"use client";

import { Builder, withChildren } from "@builder.io/react";
import {
  ContactForm as SystemContactForm,
  useContactForm,
} from "@mioto/design-system/ContactForm";

type Props = {
  submitText: string;
  successMessage: string;
  [x: string]: any;
};

function ContactForm({ submitText, successMessage }: Props) {
  const contactForm = useContactForm();

  return (
    <SystemContactForm
      contactForm={contactForm}
      submitText={submitText}
      successMessage={successMessage}
    />
  );
}

Builder.registerComponent(withChildren(ContactForm), {
  name: "Contact Form",
  inputs: [
    {
      name: "submitText",
      friendlyName: "Senden Button Text",
      type: "string",
      defaultValue: "Senden",
    },
    {
      name: "successMessage",
      type: "string",
      defaultValue: "Vielen Dank für deine Nachricht!",
      friendlyName: "Erfolgsmeldung nach Versand.",
    },
  ],
});
