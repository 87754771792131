import { Builder } from "@builder.io/react";
import {
  type InputProps,
  Input as SystemInput,
} from "@mioto/design-system/Input";

const Input = ({
  disabled,
  placeholder,
  name,
}: Pick<InputProps, "disabled" | "placeholder" | "name">) => {
  return (
    <SystemInput disabled={disabled} placeholder={placeholder} name={name} />
  );
};

Builder.registerComponent(Input, {
  name: "Form:Input",
  ...Builder.components.find((component) => component.name === "Form:Input"),
  override: true,
  inputs: [
    {
      name: "name",
      type: "string",
      friendlyName: "The input name. Used to identify the input in a form.",
      required: true,
    },
    {
      name: "disabled",
      type: "boolean",
      defaultValue: "false",
      friendlyName: "Disable the input",
    },
    {
      name: "placeholder",
      type: "string",
    },
  ],
});
