import { z } from "zod";
import { createClientEnv, createEnv } from "./createEnv";

export const globalEnv = createEnv({
  DATABASE_URL: z.string().url(),
  AZURE_OPENAI_API_KEY: z.string(),
  CLIENT_ENDPOINT: z.string().url(),
  WEBSOCKET_ENDPOINT: z.string().url(),
  WEBSOCKET_HTTP_ENDPOINT: z.string().url(),
});

export const globalClientEnv = createClientEnv(
  {
    NEXT_PUBLIC_APP_ENV: z.enum(["development", "testing", "production"]),
    NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
  },
  {
    NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
);
