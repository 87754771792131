import { Builder } from "@builder.io/sdk";
import { Accordion } from "@mioto/design-system/Accordion";
import { headingClasses } from "@mioto/design-system/Heading/classes";

export function BuilderAccordion({
  items,
}: {
  items: { trigger: string; content: string }[];
}) {
  return (
    <Accordion.Root type="multiple" className="border-gray4 border rounded">
      {items?.map((item, index) => (
        <Accordion.Item
          value={item.trigger}
          key={item.trigger}
          className="border-gray4 last:border-0 border-t-0"
        >
          <Accordion.Trigger
            className={headingClasses({
              size: "extra-small",
              className:
                "text-start grid grid-cols-[max-content_1fr_max-content] gap-2 items-center p-4",
            })}
          >
            {index + 1}. {item.trigger}
            <Accordion.Arrow />
          </Accordion.Trigger>
          <Accordion.Content className="p-4 pt-0 text-mediumText">
            {item.content}
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
}

Builder.registerComponent(BuilderAccordion, {
  name: "Accordion",
  inputs: [
    {
      name: "items",
      type: "list",
      subFields: [
        {
          name: "trigger",
          type: "string",
          friendlyName: "Item Header Text",
        },
        {
          name: "content",
          type: "string",
          friendlyName: "Item Content",
        },
      ],
      friendlyName: "Accordion Items",
    },
  ],
});
