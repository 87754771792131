import { Builder } from "@builder.io/react";
import {
  type HeadingProps,
  Heading as SystemHeading,
} from "@mioto/design-system/Heading";
import { headingClasses } from "@mioto/design-system/Heading/classes";

const Heading = ({
  children,
  as,
  size,
}: Pick<HeadingProps, "children" | "size"> & {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
}) => {
  const level = as.split("h")[1] as any as number;

  return (
    <SystemHeading level={level} size={size} className="font-serif">
      {children}
    </SystemHeading>
  );
};

Builder.registerComponent(Heading, {
  name: "Heading",
  inputs: [
    {
      name: "children",
      type: "string",
      defaultValue: "Heading",
      friendlyName: "Content of the Heading",
    },
    {
      name: "size",
      type: "string",
      defaultValue: "medium",
      enum: Object.keys(headingClasses.variants.size),
    },
    {
      name: "as",
      type: "string",
      defaultValue: "h2",
      enum: ["h1", "h2", "h3", "h4", "h5", "h6", "p"],
    },
  ],
});
