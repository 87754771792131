import { Builder } from "@builder.io/react";
import { type Props, Logo as SystemLogo } from "@mioto/design-system/Logo";

const Logo = (props: Props) => {
  return <SystemLogo {...props} />;
};

Builder.registerComponent(Logo, {
  name: "Logo",
  inputs: [
    {
      name: "monochrome",
      type: "boolean",
      defaultValue: false,
    },
    {
      name: "large",
      type: "boolean",
      defaultValue: false,
    },
  ],
});
