import { Builder } from "@builder.io/react";
import { type LinkProps, Link as SystemLink } from "@mioto/design-system/Link";
import { LinkWithAnimatedArrow } from "@mioto/design-system/LinkWithAnimatedArrow";

const Link = ({
  arrow,
  attributes,
  builderBlock: _1,
  builderState: _2,
  ...props
}: Pick<LinkProps, "children" | "size" | "href" | "emphasize" | "underline"> & {
  attributes: any;
  arrow: boolean;
  builderBlock: any;
  builderState: any;
}) => {
  return arrow ? (
    <LinkWithAnimatedArrow {...props} />
  ) : (
    <SystemLink {...props} />
  );
};

export const linkInputs = {
  children: {
    name: "children",
    type: "text",
    defaultValue: "Linktext",
    friendlyName: "Content of the Link",
  },
  size: {
    name: "size",
    type: "string",
    enum: ["small", "medium", "large"],
    defaultValue: "medium",
    friendlyName: "Size of the Link",
  },
  emphasize: {
    name: "emphasize",
    type: "boolean",
    defaultValue: "false",
  },
  arrow: {
    name: "arrow",
    type: "boolean",
    defaultValue: false,
    friendlyName: "Show right arrow",
  },
  underline: {
    name: "underline",
    type: "boolean",
    defaultValue: true,
    friendlyName: "Unterstrich bei Nutzer intent anzeigen.",
  },
  href: {
    name: "href",
    type: "string",
    friendlyName: "Linkziel",
    required: true,
    defaultValue: "https://google.com",
    helperText:
      "Wenn der Link zu einer unserer Seiten führt dann muss er in der relativen Form wie bspw. /blog angebenen werden. Externe Links werden mit ihrer kompletten URL wie bspw. https://google.com angegeben.",
  },
};

Builder.registerComponent(Link, {
  name: "Link",
  override: true,
  noWrap: true,
  inputs: Object.values(linkInputs),
});
