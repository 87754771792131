import { Builder } from "@builder.io/react";
import Heading from "@mioto/design-system/Heading";
import Text from "@mioto/design-system/Text";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import React from "react";

type TeamMember = {
  src: string;
  name: string;
  role: string;
};

const TeamSection = ({ teamMembers }: { teamMembers: TeamMember[] }) => {
  return (
    <div className="grid md:grid-cols-2 auto-rows-[250px] md:auto-rows-auto md:grid-rows-[300px_300px] gap-8">
      {teamMembers?.map((teamMember) => (
        <TeamImage key={teamMember.name} {...teamMember} />
      ))}
    </div>
  );
};

const TeamImage = ({ name, role, src }: TeamMember) => {
  const [isFlipped, setIsFlipped] = React.useState(false);

  return (
    <motion.div
      className="relative"
      key={name}
      onClick={() => setIsFlipped((isFlipped) => !isFlipped)}
      onHoverStart={() => setIsFlipped(true)}
      onHoverEnd={() => setIsFlipped(false)}
    >
      <Image src={src} alt="Team Image" fill className="object-contain" />
      <AnimatePresence>
        {isFlipped ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="h-full w-full bg-primary2/90 absolute z-10 flex items-center justify-center flex-col"
          >
            <Heading size="small">{name}</Heading>
            <Text>{role}</Text>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </motion.div>
  );
};

Builder.registerComponent(TeamSection, {
  name: "Team",
  inputs: [
    {
      name: "teamMembers",
      type: "list",
      friendlyName: "Team Members",
      subFields: [
        {
          name: "src",
          type: "file",
          friendlyName: "Image",
        },
        {
          name: "name",
          type: "string",
          friendlyName: "Team member name",
        },
        {
          name: "role",
          type: "string",
          friendlyName: "Team member role",
        },
      ],
    },
  ],
});
