"use client";

import { usePostHog } from "@mioto/analytics/client";
import { globalClientEnv } from "@mioto/env";
import { setUser } from "@sentry/nextjs";
import * as React from "react";

type Props = {
  userUuid: string;
  userEmail: string;
  userOrganizationUuid: string;
  userOrganizationName: string | null;
};

export function AnalyticsIdentify({
  userEmail,
  userUuid,
  userOrganizationUuid,
  userOrganizationName,
}: Props) {
  const posthog = usePostHog();

  React.useEffect(() => {
    posthog?.identify(userUuid, {
      employee: userEmail.includes("mioto.app"),
      email: userEmail,
      organizationUuid: userOrganizationUuid,
      organizationName: userOrganizationName,
      env: globalClientEnv.NEXT_PUBLIC_APP_ENV,
    });

    setUser({
      email: userEmail,
      id: userUuid,
    });
  }, [
    posthog,
    userEmail,
    userOrganizationName,
    userOrganizationUuid,
    userUuid,
  ]);

  return null;
}
