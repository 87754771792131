import { Builder } from "@builder.io/react";
import type { TextProps } from "@mioto/design-system/Text";
import { textClasses } from "@mioto/design-system/Text/classes";

const Text = ({
  children,
  size,
  emphasize,
}: Pick<TextProps, "children" | "size" | "emphasize">) => {
  return <p className={textClasses({ size, emphasize })}>{children}</p>;
};

Builder.registerComponent(Text, {
  name: "Text",
  inputs: [
    {
      name: "children",
      type: "string",
      defaultValue: "Text",
      friendlyName: "Content of the Text",
    },
    {
      name: "size",
      type: "string",
      defaultValue: "medium",
      enum: Object.keys(textClasses.variants.size),
    },
    {
      name: "emphasize",
      type: "string",
      defaultValue: "none",
      enum: Object.keys(textClasses.variants.emphasize),
    },
  ],
});
