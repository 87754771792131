import { createClientEnv, createEnv } from "@mioto/env/createEnv";
import { z } from "zod";

export const builderEnv = createEnv({
  SENTRY_AUTH_TOKEN: z.string().optional(),
  SENTRY_URL: z.string().url().optional(),
  SENTRY_ORG: z.string().optional(),
  OPENAI_API_KEY: z.string(),
  LANGCHAIN_API_KEY: z.string(),
  LANGCHAIN_ENDPOINT: z.string(),
  LANGCHAIN_PROJECT: z.string(),
  GOTENBERG_ENDPOINT: z.string(),
  POSTHOG_TOKEN: z.string(),
  REDIRECTS: z.preprocess(
    (value) => typeof value === "string" && JSON.parse(value),
    z.record(z.string()),
  ),
  MAINTENANCE_MODE: z.coerce.boolean(),
  MAINTENANCE_MODE_UNTIL: z.coerce.date().optional(),
});

export const builderClientEnv = createClientEnv(
  {
    NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
    NEXT_PUBLIC_BUILDER_IO: z.string(),
  },
  {
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_BUILDER_IO: process.env.NEXT_PUBLIC_BUILDER_IO,
  },
);

export default builderEnv;
