import { Builder } from "@builder.io/react";
import { ButtonLink as SystemButtonLink } from "@mioto/design-system/Button";
import type { LinkProps } from "@mioto/design-system/Link";
import { type ButtonInputProps, buttonInputs } from "./Button";

const ButtonLink = ({
  attributes: { key },
  builderBlock: _1,
  builderState: _2,
  ...props
}: Pick<LinkProps, "children" | "size" | "href" | "emphasize"> &
  ButtonInputProps & {
    attributes: any;
    builderBlock: any;
    builderState: any;
  }) => {
  return <SystemButtonLink key={key} {...props} />;
};

Builder.registerComponent(ButtonLink, {
  name: "ButtonLink",
  noWrap: true,
  inputs: [
    {
      name: "href",
      type: "string",
      friendlyName: "Linkziel",
      required: true,
      defaultValue: "https://google.com",
      helperText:
        "Wenn der Link zu einer unserer Seiten führt dann muss er in der relativen Form wie bspw. /blog angebenen werden. Externe Links werden mit ihrer kompletten URL wie bspw. https://google.com angegeben.",
    },
    ...buttonInputs,
  ],
});
